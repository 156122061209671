import Constants from "~/helpers/enums/Constants";

const openConfirmDialog = (params) => (dispatch) => {
	dispatch({ type: Constants.OPEN_CONFIRM_DIALOG, payload: { params } });
};

const closeConfirmDialog = () => (dispatch) => {
	dispatch({ type: Constants.CLOSE_CONFIRM_DIALOG });
};

const openCustomDialog = (params) => (dispatch) => {
	dispatch({ type: Constants.OPEN_CUSTOM_DIALOG, payload: { params } });
};

const closeCustomDialog = () => (dispatch) => {
	dispatch({ type: Constants.CLOSE_CUSTOM_DIALOG });
};

const setBackground = (backgroundClass) => (dispatch) => {
	dispatch({ type: Constants.SET_BACKGROUND, payload: backgroundClass });
};

const closeCombinationDialog = () => (dispatch) => {
	dispatch({ type: Constants.CLOSE_COMBINATION_DIALOG });
};

export default {
	openConfirmDialog,
	closeConfirmDialog,
	setBackground,
	openCustomDialog,
	closeCustomDialog,
	closeCombinationDialog,
};
