// Permissões definidas com base nas authorities do usuário
export const ADMIN = "ROLE_ADMIN";
export const EMPLOYEE = "ROLE_EMPLOYEE";
export const SUPPLIER = "ROLE_SUPPLIER";

export default {
	ADMIN,
	EMPLOYEE,
	SUPPLIER,
};
